<template>
  <v-radio-group v-model="sValue" row @change="save">
    <v-radio
      v-for="item in arItems"
      :key="`invoice-print-${item.value}`"
      :label="$t(item.text)"
      :value="item.value"
    />
  </v-radio-group>
</template>

<script lang="ts">
import { Component, VModel, Vue } from "vue-property-decorator";
import { SelectItemData } from "@/types/utils";

@Component
export default class InvoicePrintSelect extends Vue {
  @VModel({ type: String, default: "" }) sValue!: string;
  arItems: SelectItemData[] = [
    { text: "paper.a.4", value: "a4" },
    { text: "paper.roll", value: "roll" },
  ];

  save(sValue: string) {
    this.$emit("change", sValue);
  }
}
</script>
